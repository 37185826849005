// [HybridCodegen] hash 9524a59b55381c01220cdfc7112c04e4

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/appAssister/viewDocument/appAssisterViewTranscript.route'
    );
    const Component = module.RouteAppAssisterViewTranscript.component;
    const wrappers = module.RouteAppAssisterViewTranscript.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { documentID: 'int' });
export const AppAssisterViewTranscriptRoute: RouteObject = {
    path: 'transcript/:documentID',

    element: <WithParams />,
};
