import { css } from '@emotion/react';
import { Config, Infer } from '@yesness/superql-react';
import React, { DependencyList, useMemo } from 'react';
import { Label } from '../components/fieldLabel';
import InputRadio from '../components/input/inputRadio';
import { InputTextAreaEx } from '../components/input/inputTextAreaEx';
import { SET_ROOT_ERROR } from '../contexts/rootErrorContext';
import { SharedUtil } from '../generated/syncShared/syncShared';
import { State } from '../hooks/stateHooks';
import {
    AppInstance,
    AppInstanceState,
    ApplicantScoreType,
    AppTemplate,
    LetterOfRecommendationStatus,
} from '../superql-generated/objects';

type CAppTemplate = Config<
    AppTemplate,
    {
        applicantReleaseDate: Infer;
        applicantDeadline: Infer;
    }
>;

type CAppInstance = Config<
    AppInstance,
    {
        state: Infer;
    }
>;

export enum ApplicationCombinedState {
    OpenNotStarted,
    OpenInProgress,
    OpenCompleted,
    ClosedNotStarted,
    ClosedInProgress,
    ClosedCompleted,
}

export type MaybeClass = string | undefined | null | Array<MaybeClass> | false;

export type DataResponses = Record<string, any>;

export class Util {
    static alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

    static internalLetterStatusDisplay: Record<
        LetterOfRecommendationStatus,
        string
    > = {
        [LetterOfRecommendationStatus.Cancelled]: 'Cancelled',
        [LetterOfRecommendationStatus.PendingAcceptance]: 'Pending acceptance',
        [LetterOfRecommendationStatus.Declined]: 'Declined',
        [LetterOfRecommendationStatus.Accepted]: 'Pending submission',
        [LetterOfRecommendationStatus.Submitted]: 'Submitted',
        [LetterOfRecommendationStatus.Import_PendingApplicantSelect]:
            'Pending import',
        [LetterOfRecommendationStatus.Import_ApplicantSelected]: 'Selected',
    };

    static createContext<T>(): React.Context<T> {
        return React.createContext(null as any);
    }

    static now(): number {
        return new Date().getTime();
    }

    static todoSupportDate(strDate: string): number {
        return parseInt(strDate);
    }

    static plural(count: number): string {
        return count === 1 ? '' : 's';
    }

    static isAppTemplateOpen(
        appTemplate: CAppTemplate['o'],
        now: number
    ): boolean {
        const releaseDate = Util.todoSupportDate(
            appTemplate.applicantReleaseDate
        );
        const deadline = Util.todoSupportDate(appTemplate.applicantDeadline);
        return now >= releaseDate && now < deadline;
    }

    static getApplicationCombinedState({
        now,
        appTemplate,
        appInstance,
    }: {
        now: number;
        appTemplate: CAppTemplate['o'];
        appInstance?: CAppInstance['o'] | null;
    }): ApplicationCombinedState {
        const open = this.isAppTemplateOpen(appTemplate, now);
        if (appInstance != null) {
            switch (appInstance.state) {
                case AppInstanceState.Submitted:
                    return open
                        ? ApplicationCombinedState.OpenCompleted
                        : ApplicationCombinedState.ClosedCompleted;
                case AppInstanceState.Open:
                    return open
                        ? ApplicationCombinedState.OpenInProgress
                        : ApplicationCombinedState.ClosedInProgress;
            }
        } else {
            return open
                ? ApplicationCombinedState.OpenNotStarted
                : ApplicationCombinedState.ClosedNotStarted;
        }
    }

    static resizeImage(
        image: CanvasImageSource,
        w: number,
        h: number
    ): HTMLCanvasElement {
        const canvas = document.createElement('canvas');
        canvas.width = w;
        canvas.height = h;
        const ctx = canvas.getContext('2d');
        if (ctx === null) {
            throw new Error('Failed to get canvas context');
        }
        ctx.drawImage(image, 0, 0, w, h);
        return canvas;
    }

    static pad(num: number | string, len: number): string {
        return num.toString().padStart(len, '0');
    }

    static prettyFormat(rawValue: string, lengths: number[]): string {
        const value = rawValue.replace(/[^\d\/]/g, ''); // remove all non digits and non forward slashes
        const spl = value.split('/').slice(0, lengths.length); // Each part should be a number or the empty string
        const last = spl.length - 1;
        if (spl[last].length > lengths[last]) {
            const extra = spl[last].substring(lengths[last]);
            spl[last] = spl[last].substring(0, lengths[last]);
            if (spl.length < lengths.length) {
                spl.push(extra);
            }
        }
        for (let i = 0; i < spl.length; i++) {
            if (i < spl.length - 1) {
                spl[i] = this.pad(spl[i], lengths[i] ?? 2);
            }
            if (spl[i].length > lengths[i]) {
                spl[i] = spl[i].substring(0, lengths[i]);
            }
        }
        return spl.join('/');
    }

    private static randomKey(length: number): string {
        let key = '';
        for (let i = 0; i < length; i++) {
            key += Util.alpha[Math.floor(Math.random() * Util.alpha.length)];
        }
        return key;
    }

    static randomKeyExcluding(length: number, existing: string[]): string {
        let key = this.randomKey(length);
        while (existing.includes(key)) {
            key = this.randomKey(length);
        }
        return key;
    }

    static boundNumber(
        num: number,
        bounds: {
            min?: number;
            max?: number;
        }
    ): number {
        if (bounds.min != null) {
            num = Math.max(num, bounds.min);
        }
        if (bounds.max != null) {
            num = Math.min(num, bounds.max);
        }
        return num;
    }

    static classes(...classesRaw: MaybeClass[]): string | undefined {
        const list: string[] = [];
        for (const cls of classesRaw) {
            const result = Array.isArray(cls) ? this.classes(...cls) : cls;
            if (result != null && result !== false) {
                list.push(result);
            }
        }
        return list.join(' ');
    }

    static sleep(timeMS: number): Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, timeMS));
    }

    static keys<T extends object>(obj: T): Array<keyof T> {
        return Object.keys(obj) as any;
    }

    static keysNumber<T extends object>(obj: T): Array<keyof T> {
        return Object.keys(obj).map((k) => parseInt(k)) as any;
    }

    static toggle<T>(arr: T[], val: T): T[] {
        const newValues = arr.slice();
        const idx = newValues.indexOf(val);
        if (idx === -1) {
            newValues.push(val);
        } else {
            newValues.splice(idx, 1);
        }
        return newValues;
    }

    static containsInsensitive(haystack: string, needle: string): boolean {
        return haystack.toLowerCase().includes(needle.toLowerCase());
    }

    static strEqualsInsensitive(a: string, b: string): boolean {
        return a.toLowerCase() === b.toLowerCase();
    }

    static ROUTE_EMPTY_VAR_INT: number = 123321;

    static getRouteWithEmptyVariables(route: string): string {
        return route.replace(
            new RegExp(this.ROUTE_EMPTY_VAR_INT.toString(), 'g'),
            ''
        );
    }

    /**
     * Returns the count of letters that count towards LOR max
     */
    static countLetters(
        letters: Array<{ status: LetterOfRecommendationStatus }>
    ): number {
        return letters.filter((letter) =>
            SharedUtil.letterOfRecStatusCountsTowardsMax(letter.status)
        ).length;
    }

    static sameDeps(oldDeps: any, newDeps: DependencyList): boolean {
        if (!Array.isArray(oldDeps) || oldDeps.length !== newDeps.length) {
            return false;
        }
        for (let i = 0; i < oldDeps.length; i++) {
            if (oldDeps[i] !== newDeps[i]) {
                return false;
            }
        }
        return true;
    }

    static canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
        return new Promise((resolve) => canvas.toBlob(resolve));
    }

    static isEmptyValue(val: any): boolean {
        return (
            val == null || (typeof val === 'string' && val.trim().length === 0)
        );
    }

    static renderDate(date: { year: number; month: number }): string {
        const d = new Date(date.year, date.month - 1, 1);
        return `${d.toLocaleString('default', {
            month: 'long',
        })} ${d.getFullYear()}`;
    }

    static maybeDate(date: any): { year: number; month: number } | null {
        const year = date?.year;
        const month = date?.month;
        if (typeof year !== 'number' || typeof month !== 'number') {
            return null;
        }
        return { year, month };
    }

    static prettyBytes(bytes: number, numDecimals: number = 0): string {
        const r = (n: number, suffix: string) =>
            `${n.toFixed(numDecimals)} ${suffix}${
                numDecimals === 0 ? Util.plural(n) : 's'
            }`;
        if (bytes < 1024) {
            return r(bytes, 'byte');
        }
        const kilobytes = bytes / 1024;
        if (kilobytes < 1024) {
            return r(kilobytes, 'kilobyte');
        }
        const megabytes = kilobytes / 1024;
        return r(megabytes, 'megabyte');
    }

    static download(url: string, downloadName?: string) {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        if (downloadName != null) {
            a.download = downloadName;
        }
        document.body.appendChild(a);
        a.click();
        // set timeout to work on firefox apparently
        setTimeout(() => {
            document.body.removeChild(a);
        }, 0);
    }

    static downloadData(data: string, name: string) {
        const blob = new Blob([data]);
        const url = URL.createObjectURL(blob);
        this.download(url, name);
        URL.revokeObjectURL(url);
    }

    static pull<T, K extends string | number, V>(
        objects: T[],
        getKey: (obj: T) => K,
        getVal: (obj: T) => V
    ): Record<K, V> {
        const map: Record<K, V> = {} as any;
        for (const obj of objects) {
            map[getKey(obj)] = getVal(obj);
        }
        return map;
    }

    static renderCSV(rows: string[][]): string {
        const processRow = function (row: string[]) {
            let finalVal = '';
            for (let j = 0; j < row.length; j++) {
                const innerValue = row[j] == null ? '' : row[j].toString();
                let result = innerValue.replace(/"/g, '""');
                if (result.search(/("|,|\n)/g) >= 0)
                    result = '"' + result + '"';
                if (j > 0) finalVal += ',';
                finalVal += result;
            }
            return finalVal + '\n';
        };

        let csvFile = '';
        for (let i = 0; i < rows.length; i++) {
            csvFile += processRow(rows[i]);
        }
        return csvFile;
    }

    static devLog(...data: any[]) {
        if ((window as any).LCA_DEV_MODE) {
            console.log(...data);
        }
    }
}

export async function wrapAsync<T>(
    promiseOrFunc: (() => Promise<T>) | Promise<T>
): Promise<T> {
    let promise = promiseOrFunc;
    if (typeof promise === 'function') {
        promise = promise();
    }
    try {
        return await promise;
    } catch (e) {
        SET_ROOT_ERROR('wrapAsync', e);
        return null as any;
    }
}

export function executeAsync(
    promiseOrFunc: (() => Promise<void>) | Promise<void>
) {
    wrapAsync(promiseOrFunc);
}

export function idx<TK extends string | number | symbol, TV>(
    map: Record<TK, TV>,
    key: TK | null | undefined
): TV | null {
    if (key == null) return null;
    return map[key] ?? null;
}

export function nullthrows<T>(
    value: T | null | undefined,
    message?: string
): T {
    if (value == null) {
        throw new Error(message ?? 'Value cannot be null');
    }
    return value;
}

type FailedExamValue = {
    failedExams: string;
} | null;

function parseFailedExamValue(raw: string): FailedExamValue {
    try {
        return JSON.parse(raw);
    } catch (e) {
        return null;
    }
}

function ApplicantScoreFailedPastExams({
    value: raw,
    onChange: onChangeRaw,
    disabled,
}: RenderApplicantInputProps) {
    const value = useMemo(() => parseFailedExamValue(raw), [raw]);
    const onChange = (newVal: FailedExamValue) =>
        onChangeRaw(JSON.stringify(newVal));
    return (
        <>
            <Label label="Any previous failed STEP/COMLEX exam attempts?">
                <InputRadio
                    options={{
                        yes: 'Yes',
                        no: 'No',
                    }}
                    value={value === null ? (raw === '' ? null : 'no') : 'yes'}
                    onChange={(val) =>
                        onChange(
                            val === 'no'
                                ? null
                                : {
                                      failedExams: '',
                                  }
                        )
                    }
                    disabled={disabled}
                />
            </Label>
            {value !== null && (
                <Label label="Please list the exams and their dates">
                    <InputTextAreaEx
                        value={value.failedExams}
                        onChange={(newValue) =>
                            onChange({
                                failedExams: newValue,
                            })
                        }
                        autoGrow
                        maxHeight={200}
                        disabled={disabled}
                    />
                </Label>
            )}
        </>
    );
}

type RenderApplicantInputProps = State<string> & { disabled?: boolean };

export const applicantScoreTypeMap: Record<
    ApplicantScoreType,
    {
        display: string;
        renderScoreForReview?: (value: string) => React.ReactNode;
        renderApplicantInput?: React.ComponentType<RenderApplicantInputProps>;
    }
> = {
    [ApplicantScoreType.Step1]: {
        display: 'USMLE Step 1',
    },
    [ApplicantScoreType.Step2]: {
        display: 'USMLE Step 2 CK',
    },
    [ApplicantScoreType.Comlex1]: {
        display: 'COMLEX 1',
    },
    [ApplicantScoreType.Comlex2]: {
        display: 'COMLEX 2',
    },
    [ApplicantScoreType.FailedPastExams]: {
        display: 'Any previous failed STEP/COMLEX exam attempts?',
        renderApplicantInput: ApplicantScoreFailedPastExams,
        renderScoreForReview: (raw) => {
            const value = parseFailedExamValue(raw);
            if (value === null) {
                return 'No';
            } else {
                return (
                    <div css={css({ whiteSpace: 'pre-wrap' })}>
                        Yes:
                        <br />
                        {value.failedExams}
                    </div>
                );
            }
        },
    },
};

export function RenderApplicantScore(props: {
    type: ApplicantScoreType;
    value: string;
}) {
    const config = applicantScoreTypeMap[props.type];
    let ret;
    if (config.renderScoreForReview != null) {
        ret = config.renderScoreForReview(props.value);
    } else {
        ret = props.value;
    }
    return <>{ret}</>;
}

export function shouldDisplayApplicantScore(score: {
    type: ApplicantScoreType;
    value: string;
}): boolean {
    return (
        !Util.isEmptyValue(score.value) ||
        score.type === ApplicantScoreType.FailedPastExams
    );
}
