// [HybridCodegen] hash 33c9e6b817674183437f3fbf77188d20

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/administrator/emailManager/administratorEmailManager.route'
    );
    const Component = module.RouteAdministratorEmailManager.component;
    const wrappers = module.RouteAdministratorEmailManager.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const AdministratorEmailManagerRoute: RouteObject = {
    path: 'emailManager',

    element: <Component />,
};
