// [HybridCodegen] hash c55f869b21d8ab785b7180db8e7c99d0

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/applicant/applicationManager/appInstance/applicantAppInstanceTask/applicantAppInstanceTask.route'
    );
    const Component = module.RouteApplicantAppInstanceTask.component;
    const wrappers = module.RouteApplicantAppInstanceTask.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, {
    appInstance: 'int',
    task: 'string',
});
export const ApplicantAppInstanceTaskRoute: RouteObject = {
    path: ':task',

    element: <WithParams />,
};
