// [HybridCodegen] hash 4f64b31c1f878cc11c9f3e711d24fe4b

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import('../../../pages/public/faq/faq.route');
    const Component = module.RouteFaq.component;
    const wrappers = module.RouteFaq.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FaqRoute: RouteObject = {
    path: '/faq',

    element: <Component />,
};
