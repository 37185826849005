// [HybridCodegen] hash 2ab4f5a370bc6952c8e7e8ff47b7841f

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/myTeam/facultyMyTeam.route'
    );
    const Component = module.RouteFacultyMyTeam.component;
    const wrappers = module.RouteFacultyMyTeam.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyMyTeamRoute: RouteObject = {
    path: 'myTeam',

    element: <Component />,
};
