// [HybridCodegen] hash 2d5c66297c861ec2d85488741605b393

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import * as AccountAccessRouteChildren from './accountAccess';
import * as FacultyApplicationsRouteChildren from './facultyApplications';
import * as FacultyArchiveRouteChildren from './facultyArchive';
import * as FacultyCompletedReviewsRouteChildren from './facultyCompletedReviews';
import * as FacultyCustomizeApplicationRouteChildren from './facultyCustomizeApplication';
import * as FacultyEditRubricRouteChildren from './facultyEditRubric';
import * as FacultyGroupsRouteChildren from './facultyGroups';
import * as FacultyViewGroupRouteChildren from './facultyViewGroup';
import * as FacultyHomeRouteChildren from './facultyHome';
import * as FacultyInterviewManagerRouteChildren from './facultyInterviewManager';
import * as FacultyManageReviewsRouteChildren from './facultyManageReviews';
import * as FacultyMyTeamRouteChildren from './facultyMyTeam';
import * as FacultyRecommendationsRouteChildren from './facultyRecommendations';
import * as FacultyViewRecommendationRouteChildren from './facultyViewRecommendation';

const Component = lazy(async () => {
    const module = await import('../../../pages/faculty/faculty.route');
    const Component = module.RouteFaculty.component;
    const wrappers = module.RouteFaculty.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyRoute: RouteObject = {
    path: '/faculty',

    element: <Component />,
    children: [
        ...Object.values(AccountAccessRouteChildren),
        ...Object.values(FacultyApplicationsRouteChildren),
        ...Object.values(FacultyArchiveRouteChildren),
        ...Object.values(FacultyCompletedReviewsRouteChildren),
        ...Object.values(FacultyCustomizeApplicationRouteChildren),
        ...Object.values(FacultyEditRubricRouteChildren),
        ...Object.values(FacultyGroupsRouteChildren),
        ...Object.values(FacultyViewGroupRouteChildren),
        ...Object.values(FacultyHomeRouteChildren),
        ...Object.values(FacultyInterviewManagerRouteChildren),
        ...Object.values(FacultyManageReviewsRouteChildren),
        ...Object.values(FacultyMyTeamRouteChildren),
        ...Object.values(FacultyRecommendationsRouteChildren),
        ...Object.values(FacultyViewRecommendationRouteChildren),
    ],
};
