// [HybridCodegen] hash ee5995d8e834188c37bd4bdad1074907

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/recommender/viewRecommendation/facultyViewRecommendation.route'
    );
    const Component = module.RouteFacultyViewRecommendation.component;
    const wrappers = module.RouteFacultyViewRecommendation.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { recommendationID: 'int' });
export const FacultyViewRecommendationRoute: RouteObject = {
    path: 'recommendations/:recommendationID',

    element: <WithParams />,
};
