// [HybridCodegen] hash 5a48ab2eef68cd7be4d8b4939bc5a05c

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/accountAccess/accountAccess.route'
    );
    const Component = module.RouteAccountAccess.component;
    const wrappers = module.RouteAccountAccess.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const AccountAccessRoute: RouteObject = {
    path: 'accountAccess',

    element: <Component />,
};
