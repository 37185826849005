// [HybridCodegen] hash 81fd01fa9feebd1dfbe50b8d29b47693

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import * as AdministratorEmailManagerRouteChildren from './administratorEmailManager';
import * as AdministratorHomeRouteChildren from './administratorHome';
import * as AdministratorIconsRouteChildren from './administratorIcons';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/administrator/administrator.route'
    );
    const Component = module.RouteAdministrator.component;
    const wrappers = module.RouteAdministrator.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const AdministratorRoute: RouteObject = {
    path: '/administrator',

    element: <Component />,
    children: [
        ...Object.values(AdministratorEmailManagerRouteChildren),
        ...Object.values(AdministratorHomeRouteChildren),
        ...Object.values(AdministratorIconsRouteChildren),
    ],
};
