// [HybridCodegen] hash 3dc4bbaf6228a66eaaae2cb18eac86fc

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/applicant/applicantMessages/applicantMessages.route'
    );
    const Component = module.RouteApplicantMessages.component;
    const wrappers = module.RouteApplicantMessages.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const ApplicantMessagesRoute: RouteObject = {
    path: 'messages',

    element: <Component />,
};
