// [HybridCodegen] hash 0116649c9b31c01dd44f7ddafc5199c8

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/applicant/applicationManager/appInstance/applicantAppInstanceTask/applicantAppInstanceTaskIndex.route'
    );
    const Component = module.RouteApplicantAppInstanceTaskIndex.component;
    const wrappers = module.RouteApplicantAppInstanceTaskIndex.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { appInstance: 'int' });
export const ApplicantAppInstanceTaskIndexRouteIndex: RouteObject = {
    index: true,
    element: <WithParams />,
};
