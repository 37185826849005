// [HybridCodegen] hash c5ff4b2a23ef0cf9d8e1800b3342b91b

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/applicant/documents/applicantDocuments.route'
    );
    const Component = module.RouteApplicantDocuments.component;
    const wrappers = module.RouteApplicantDocuments.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const ApplicantDocumentsRoute: RouteObject = {
    path: 'documents',

    element: <Component />,
};
