// [HybridCodegen] hash 804538dd2dfdc4c00d428488b19e2d9e

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/manageReviews/facultyManageReviews.route'
    );
    const Component = module.RouteFacultyManageReviews.component;
    const wrappers = module.RouteFacultyManageReviews.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyManageReviewsRoute: RouteObject = {
    path: 'manageReviews',

    element: <Component />,
};
