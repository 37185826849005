// [HybridCodegen] hash 198c8584feffa816d55db5c1ba79e367

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/public/contactUs/contactUs.route'
    );
    const Component = module.RouteContactUs.component;
    const wrappers = module.RouteContactUs.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const ContactUsRoute: RouteObject = {
    path: '/contactUs',

    element: <Component />,
};
