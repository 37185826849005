// [HybridCodegen] hash ec2565d84c64b7741e159ced0244a76e

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/archive/facultyArchive.route'
    );
    const Component = module.RouteFacultyArchive.component;
    const wrappers = module.RouteFacultyArchive.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const FacultyArchiveRoute: RouteObject = {
    path: 'archive',

    element: <Component />,
};
