// [HybridCodegen] hash fe21b1a8e9c96337f1bd982356b6ee37

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/applicant/home/applicantHome.route'
    );
    const Component = module.RouteApplicantHome.component;
    const wrappers = module.RouteApplicantHome.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const ApplicantHomeRouteIndex: RouteObject = {
    index: true,
    element: <Component />,
};
