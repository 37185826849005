// [HybridCodegen] hash a269df8682cad3e41d457807167ffa0c

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/userSettings/userSettings.route'
    );
    const Component = module.RouteUserSettings.component;
    const wrappers = module.RouteUserSettings.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
export const UserSettingsRoute: RouteObject = {
    path: '/settings',

    element: <Component />,
};
