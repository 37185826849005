// [HybridCodegen] hash f401b843c01bed2b2c1273334fa0ab12

import { lazy } from 'react';
import { applyWrappers } from '../../../util/wrapperUtil';
import { RouteObject } from 'react-router-dom';
import withParams from '../../../util/withParams';

const Component = lazy(async () => {
    const module = await import(
        '../../../pages/faculty/applications/viewApplication/facultyViewApplication.route'
    );
    const Component = module.RouteFacultyViewApplication.component;
    const wrappers = module.RouteFacultyViewApplication.wrappers ?? [];
    return {
        default: (props: React.ComponentProps<typeof Component>) => {
            return applyWrappers(wrappers, <Component {...props} />);
        },
    };
});
const WithParams = withParams(Component, { progInstanceID: 'int' });
export const FacultyViewApplicationRoute: RouteObject = {
    path: ':progInstanceID',

    element: <WithParams />,
};
